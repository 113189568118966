import store from "@/store";
import {
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import API from "@/store/api/administrative_area";
import { PaginatedTransportList } from "../model/transport";
import { District, Palika, Province } from "../model/administrative_area";

@Module({ name: "AdministrativeAreaStore", dynamic: true, store })
class AdministrativeAreaModule extends VuexModule {
  public ProvinceList: Province = {} as Province;
  public DistrictList: District = {} as District;
  public PalikaList: Palika = {} as Palika;
  public Provinces: Province[] = [];
  public Districts: District[] = [];
  public Palikas: Palika[] = [];

  @MutationAction
  async getProvinceList(): Promise<any> {
    const response = await API.getProvinceList();
    return { ProvinceList: response };
  }

  @MutationAction
  async getProvinceS(): Promise<any> {
    const response = await API.getProvinceList();
    return { Provinces: response };
  }

  @MutationAction
  async getDistrictList(): Promise<any> {
    const response = await API.getDisrictList();
    return { Districts: response };
  }

  @MutationAction
  async getPalikaList(): Promise<any> {
    const response = await API.getPalikaList();
    return { Palikas: response };
  }

  @MutationAction
  async getDistrictByProvinceId(
    params: { filters: any } = { filters: undefined }
  ): Promise<any> {
    let query = "";

    if (params.filters) {
      query += "?";
      Object.keys(params.filters).forEach((key) => {
        query += `&${key}=${params.filters[key]}`;
      });
    }
    const response = await API.getDistrictByProvinceId(query);
    return { DistrictList: response };
  }

  @MutationAction
  async getPalikaByDistrictId(
    params: { filters: any } = { filters: undefined }
  ): Promise<any> {
    let query = "";

    if (params.filters) {
      query += "?";
      Object.keys(params.filters).forEach((key) => {
        query += `&${key}=${params.filters[key]}`;
      });
    }
    const response = await API.getPalikaByDistrictId(query);
    return { PalikaList: response };
  }
}

export default getModule(AdministrativeAreaModule);
