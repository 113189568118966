import { postApi, patchApi, getApi, deleteApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import {
  userInfo,
  forgotPassword,
  resetPassword,
  activate,
  changePassword,
} from "@/store/model/jwt";
import { PaginatedTransportList, Transport } from "@/store/model/transport";
import { MarketStore } from "@/store/modules";

export default new (class TransportAPI {
  public async getPaginatedTransportList(query: any): Promise<any> {
    const url = `${Action.Transport}`;
    const response = await getApi(url, query);
    return response as PaginatedTransportList;
  }
  public async getPaginatedTransportDateRangeList(
    startDate: string,
    endDate: string,
    query: any
  ): Promise<any> {
    const url = `${Action.TransportDateRangeList.replace(
      "<marketId>",
      MarketStore.MARKETPLACE_ID
    )
      .replace("<nepStartDate>", startDate)
      .replace("<nepEndDate>", endDate)}`;
    const response = await getApi(url, query);
    return response;
  }
  public async getPaginatedTransactionDateRangeList(
    startDate: string,
    endDate: string,
    query: any
  ): Promise<any> {
    const url = `${Action.TransactionDateRangeList.replace(
      "<marketId>",
      MarketStore.MARKETPLACE_ID
    )
      .replace("<nepStartDate>", startDate)
      .replace("<nepEndDate>", endDate)}`;
    const response = await getApi(url, query);
    return response;
  }

  public async getTransportById(id: any): Promise<any> {
    const url = `${Action.Transport}/${id}`;
    const response = await getApi(url);
    return response;
  }

  public async deleteTransportById(id: any): Promise<any> {
    const url = `${Action.Transport}`;
    const response = await deleteApi(url, id);
    return response;
  }

  public async createTransport(param: any): Promise<any> {
    const url = `${Action.Transport}`;
    const response = await postApi(url, param);
    return response as Transport;
  }

  public async updateTransport(id: any, query: any): Promise<any> {
    const url = `${Action.Transport}/${id}`;
    const response = await patchApi(url, query);
    return response as Transport;
  }
})();
