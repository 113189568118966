import store from "@/store";
import {
  Module,
  VuexModule,
  MutationAction,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import API from "@/store/api/market";
import {
  Category,
  MarketSummary,
  PaginatedProductList,
  PaginatedProductPriceList,
  Product,
  Markets,
  ProductPrice,
  ProductPriceList,
  LineChart,
  HighchartLineChartData,
  TransportVolumeChart,
} from "@/store/model/market";
import { QTablePagination } from "@/store/model/page";
import { BaseColumnChart } from "../model/chart";
@Module({ name: "MarketStore", dynamic: true, store })
class MarketModule extends VuexModule {
  public PaginatedProductList: PaginatedProductList =
    {} as PaginatedProductList;
  public CategoryList: Category[] = [];
  public ProductList: Product[] = [];
  public MarketList: Markets[] = [];
  public ProductPrice: ProductPrice = {} as ProductPrice;
  public PaginatedProductPriceList: PaginatedProductPriceList =
    {} as PaginatedProductPriceList;
  public MarketSummary: MarketSummary = {} as MarketSummary;
  public ProductPriceList: ProductPriceList = {} as ProductPriceList;
  public MarketLineChart: LineChart = {} as LineChart;

  public BaseColumnChart: BaseColumnChart = {
    title: {
      text: "",
    },
    chart: {
      type: "column",
    },
    xAxis: {
      title: {
        text: "",
      },
      categories: [],
      type: "category",
    },
    yAxis: {
      title: { text: "" },
    },
    series: [],
    legend: {
      enabled: true,
    },
    plotOptions: {
      series: {
        stacking: null,
      },
    },
  };
  public TimeSeriesLineChart: HighchartLineChartData =
    {} as HighchartLineChartData;

  public TransportLineChartData: TransportVolumeChart =
    {} as TransportVolumeChart;

  public MARKETPLACE_ID: any = 1;

  public date: any = "";

  ReportTablePagination: QTablePagination = {
    sortBy: "id",
    descending: false,
    page: 1,
    rowsPerPage: 8,
    rowsNumber: 1,
  };

  MarketDates: string[] = [];

  // public isTableLoading: boolean = false as boolean
  // public isBtnLoading: boolean = false as boolean

  // public ToggleProductFilterCard: boolean = false as boolean

  @Mutation
  async changeDate(newValue: any) {
    this.date = newValue;
    console.log(this.date);
  }
  @Mutation
  setReportPagination(data: QTablePagination) {
    this.ReportTablePagination = data;
  }

  @MutationAction
  async getPaginatedProductList(
    params: {
      searchString?: any;
      filters?: any;
      page?: any;
      market_id?: any;
    } = { searchString: undefined, page: 1 }
  ): Promise<any> {
    let query = "";
    if (
      params.searchString ||
      params.page ||
      params.filters ||
      params.market_id
    ) {
      query += "?";
      if (params.searchString) {
        query += "&search=" + params.searchString;
      }
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
      if (params.page) {
        query += "&page=" + params.page;
      }
      console.log("ii", params.market_id);
      if (params.market_id) {
        query += "&market=" + params.market_id;
      }
    }
    const response = await API.getPaginatedProductList(query);
    return { PaginatedProductList: response };
  }

  @MutationAction
  async getProductCategoryList() {
    const response = await API.getProductCategoryList();
    return { CategoryList: response };
  }

  @MutationAction
  async getProductPricebyId(params: { id: number; market_id?: any }) {
    let query = "";
    if (params.market_id) {
      query += "?";
      if (params.market_id) {
        query += "&market=" + params.market_id;
      }
    }
    const response = await API.getProductPriceListID(params.id, query);
    return { ProductPrice: response };
  }

  @MutationAction
  async getMarketsList() {
    const response = await API.getMarketsList();
    return { MarketList: response };
  }

  @MutationAction
  async getMarketPriceDates() {
    const dates = await API.getMarketPriceDates();
    return { MarketDates: dates };
  }

  @MutationAction
  async getPaginatedProductPriceList(
    params: {
      searchString?: any;
      filters?: any;
      page?: number;
      pageSize?: number;
    } = { searchString: undefined, page: 1 }
  ): Promise<any> {
    let query = "";

    console.log("Query", params);
    if (
      params.filters ||
      params.searchString ||
      params.page ||
      params.pageSize ||
      params.pageSize == 0
    ) {
      query += "?";
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }

      if (params.page) {
        query += "&page=" + params.page;
      }
      if (params.pageSize || params.pageSize == 0) {
        query += "&page_size=" + params.pageSize;
      }

      if (params.searchString) {
        query += "&search=" + params.searchString;
      }
    }

    console.log(query);
    const response = await API.getPaginatedProductPriceList(query);
    return { PaginatedProductPriceList: response };
  }

  @MutationAction
  async getPaginatedProductPriceDateRangeList(params: {
    startDate: string;
    endDate: string;
    searchString?: any;
    filters?: any;
    page?: any;
    pageSize?: any;
    commodity?: any;
  }): Promise<any> {
    let query = "";

    if (params.filters || params.searchString || params.page) {
      query += "?";
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }

      if (params.page) {
        query += "&page=" + params.page;
      } else {
        query += "&page=1";
      }

      if (params.pageSize) {
        query += "&page_size=" + params.pageSize;
      }

      if (params.commodity) {
        query += "&product__category=" + params.commodity;
      }

      if (params.searchString) {
        query += "&search=" + params.searchString;
      }
    }
    console.log(params);

    const response = await API.getPaginatedProductPriceRangeList(
      params.startDate,
      params.endDate,
      query
    );
    return { PaginatedProductPriceList: response };
  }

  @MutationAction
  async getProductList(
    params: {
      filters: any;
    } = {
      filters: null,
    }
  ): Promise<any> {
    let query = "";

    if (params.filters) {
      query = "?";
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
    }

    const response = await API.getProductList(query);
    return { ProductList: response };
  }

  @Action
  async getProductCategoryByID(category_id: any): Promise<any> {
    const res = await API.getProductCategoryByID(category_id);
    return res;
  }

  @Action
  async getProductByID(product_id: any): Promise<any> {
    const res = await API.getProductByID(product_id);
    return res;
  }

  @MutationAction
  async getMarketSummary(params: { filters: any }): Promise<any> {
    let query = "";

    if (params.filters) {
      query = "?";
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
    }

    const response = await API.getMarketSummary(query);
    return { MarketSummary: response };
  }
  @MutationAction
  async getProductPriceList(params: { filters: any }): Promise<any> {
    let query = "";

    if (params.filters) {
      query = "?";
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
    }

    const response = await API.getProductPriceList(query);
    return { ProductPriceList: response };
  }
  @MutationAction
  async getMarketLineChart(
    params: {
      filters: any;
    } = {
      filters: null,
    }
  ): Promise<any> {
    let query = "";

    if (params.filters) {
      query = "?";
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
    }

    const response = await API.getMarketLineChart(query);
    return { MarketLineChart: response };
  }
  @MutationAction
  async getMarketTimeSeriesLineHighChart(
    params: {
      filters: any;
    } = {
      filters: null,
    }
  ): Promise<any> {
    let query = "";

    if (params.filters) {
      query = "?";
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
    }

    const response = await API.getMarketTimeSeriesLineHighChart(query);
    return { TimeSeriesLineChart: response };
  }
  @MutationAction
  async getTransportChartData(
    params: {
      filters: any;
    } = {
      filters: null,
    }
  ): Promise<any> {
    let query = "";

    if (params.filters) {
      query = "?";
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
    }

    const response = await API.getTransportChartData(query);
    return { TransportLineChartData: response };
  }

  @Action
  async editProductPrice(params: { data: any; id: number; market_id: number }) {
    return await API.editProductPrice(params.data, params.id, params.market_id);
  }
}

export default getModule(MarketModule);
