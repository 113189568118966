
import { Component, Vue } from "vue-property-decorator";
@Component
export default class AppFooter extends Vue {
  navigateToHome() {
    this.$router.push({
      name: "Home",
    });
  }

  navigateToAbout() {
    this.$router.push({
      name: "About",
    });
  }

  navigateToContact() {
    this.$router.push({
      name: "Contact",
    });
  }
}
