import { postApi, getApi, deleteApi, patchApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import {
  userInfo,
  forgotPassword,
  resetPassword,
  activate,
  changePassword,
} from "@/store/model/jwt";
import { Trader } from "../model/trader";
// import { PaginatedTransportList, Transport } from "../model/transport";

export default new (class TraderAPI {
  public async getPaginatedTraderList(query: any): Promise<any> {
    const url = `${Action.Trader}`;
    const response = await getApi(url, query);
    return response;
  }

  public async createTrader(param: any): Promise<any> {
    const url = `${Action.Trader}`;
    const response = await postApi(url, param, {
      "Content-Type": "multipart/form-data",
    });
    return response as Trader;
  }
  public async editTrder(data: FormData, id: number): Promise<any> {
    const url = `${Action.Trader}/${id}`;
    const response = await patchApi(url, data, {
      "Content-Type": "multipart/form-data",
    });
    return response as Trader;
  }
  public async deleteTrader(id: number): Promise<any> {
    const url = `${Action.Trader}`;
    const response = await deleteApi(url, id);

    return response;
  }
  public async getTraderByID(traderId: any): Promise<any> {
    const url = `${Action.Trader}/${traderId}`;
    const response = await getApi(url);
    return response;
  }
})();
