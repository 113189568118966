import store from "@/store";
import {
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import API from "@/store/api/user";
import { PaginatedUserList, Role } from "../model/user";

@Module({ name: "UserStore", dynamic: true, store })
class UserModule extends VuexModule {

    public PaginatedUserList: PaginatedUserList = {} as PaginatedUserList
    public UserRole: Role = {} as Role

    @MutationAction
    async getPaginatedUserList(
        params: {
        searchString?: any;
        filters?: any;
        page?: any;
        market_id?: any;
        } = { searchString: undefined, page: 1 }
    ): Promise<any> {
        let query = "";
        if (
        params.searchString ||
        params.page ||
        params.filters ||
        params.market_id
        ) {
        query += "?";
        if (params.searchString) {
            query += "&search=" + params.searchString;
        }
        if (params.filters) {
            Object.keys(params.filters).forEach((key) => {
            query += `&${key}=${params.filters[key]}`;
            });
        }
        if (params.page) {
            query += "&page=" + params.page;
        }
        //   console.log("ii", params.market_id);
        if (params.market_id) {
            query += "&market=" + params.market_id;
        }
        }
        const response = await API.getPaginatedUserList(query);
        return { PaginatedUserList: response };
    }

    @MutationAction
    async getUserRoles(): Promise<any> {
        const response = await API.getUserRoles()
        return { UserRole: response }
    }

    @Action
    async createUser(params: { userDetail: any } ): Promise<any> {
        const response = await API.createUser(params.userDetail)
        return response
    }

}

export default getModule(UserModule);
