import { postApi, patchApi, getApi, deleteApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import {
  userInfo,
  forgotPassword,
  resetPassword,
  activate,
  changePassword,
} from "@/store/model/jwt";
import { PaginatedTransportList, Transport } from "../model/transport";
import { Role } from "../model/user";

export default new (class UserAPI {
  public async getPaginatedUserList(query: any): Promise<any> {
    const url = `${Action.User}`;
    const response = await getApi(url, query);
    return response as PaginatedTransportList;
  }

  public async getUserRoles(){
    const url = `${Action.UserRole}`;
    const response = await getApi(url)
    return response as Role
  }

  public async createUser(query: any){
    const url = `${Action.User}`;
    const response = await postApi(url, query, {
      "Content-Type": "multipart/form-data",
    })
    return response
  }
 
})();
