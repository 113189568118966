import store from "@/store";
import {
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import API from "@/store/api/transport";
import {
  PaginatedTransportList,
  PaginatedTransportReportList,
  PaginatedTransactionReportList,
} from "../model/transport";
import { QTablePagination } from "../model/page";
import { MarketStore } from ".";

@Module({ name: "TransportStore", dynamic: true, store })
class TransportModule extends VuexModule {
  public PaginatedTransportList: PaginatedTransportList =
    {} as PaginatedTransportList;
  // public Transport: Transport = {} as Transport
  public PaginatedTransportReportList: PaginatedTransportReportList =
    {} as PaginatedTransportReportList;
  public PaginatedTransactionReportList: PaginatedTransactionReportList =
    {} as PaginatedTransactionReportList;

  @MutationAction
  async getPaginatedTransportList(
    params: {
      searchString?: any;
      filters?: any;
      page?: any;
    } = { searchString: undefined, page: 1 }
  ): Promise<any> {
    let query = "";
    query += "?market=" + MarketStore.MARKETPLACE_ID;
    if (params.searchString || params.page || params.filters) {
      if (params.searchString) {
        query += "&search=" + params.searchString;
      }
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
      if (params.page) {
        query += "&page=" + params.page;
      }
    }
    const response = await API.getPaginatedTransportList(query);
    return { PaginatedTransportList: response };
  }

  ReportTablePagination: QTablePagination = {
    sortBy: "id",
    descending: false,
    page: 1,
    rowsPerPage: 8,
    rowsNumber: 1,
  };

  TransactionReportTablePagination: QTablePagination = {
    sortBy: "id",
    descending: false,
    page: 1,
    rowsPerPage: 8,
    rowsNumber: 1,
  };

  @Mutation
  setReportPagination(data: QTablePagination) {
    this.ReportTablePagination = data;
  }

  @Mutation
  setTransactionReportPagination(data: QTablePagination) {
    this.TransactionReportTablePagination = data;
  }

  @MutationAction
  async getPaginatedTransportDateRangeList(params: {
    startDate: string;
    endDate: string;
    searchString?: any;
    filters?: any;
    page?: any;
    pageSize?: any;
    commodity?: any;
  }): Promise<any> {
    let query = "";

    if (params.filters || params.searchString || params.page) {
      query += "?";
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }

      if (params.page) {
        query += "&page=" + params.page;
      } else {
        query += "&page=1";
      }

      if (params.pageSize) {
        query += "&page_size=" + params.pageSize;
      }

      if (params.commodity) {
        query += "&product__category=" + params.commodity;
      }

      if (params.searchString) {
        query += "&search=" + params.searchString;
      }
    }
    console.log(params);

    const response = await API.getPaginatedTransportDateRangeList(
      params.startDate,
      params.endDate,
      query
    );
    return { PaginatedTransportReportList: response };
  }

  @MutationAction
  async getPaginatedTransactionDateRangeList(params: {
    startDate: string;
    endDate: string;
    searchString?: any;
    filters?: any;
    page?: any;
    pageSize?: any;
    commodity?: any;
  }): Promise<any> {
    let query = "";

    if (params.filters || params.searchString || params.page) {
      query += "?";
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }

      if (params.page) {
        query += "&page=" + params.page;
      } else {
        query += "&page=1";
      }

      if (params.pageSize) {
        query += "&page_size=" + params.pageSize;
      }

      if (params.commodity) {
        query += "&product__category=" + params.commodity;
      }

      if (params.searchString) {
        query += "&search=" + params.searchString;
      }
    }
    console.log(params);

    const response = await API.getPaginatedTransactionDateRangeList(
      params.startDate,
      params.endDate,
      query
    );
    return { PaginatedTransactionReportList: response };
  }

  @Action
  async getTransportById(params: { transportId: any }): Promise<any> {
    const response = await API.getTransportById(params.transportId);
    console.log(response);
    return response;
  }

  @Action
  async deleteTransportById(params: { transportId: any }): Promise<any> {
    const response = await API.deleteTransportById(params.transportId);
    return response;
  }

  @Action
  async createTransport(params: { transport: any }): Promise<any> {
    const response = await API.createTransport(params.transport);
    return response;
  }

  @Action
  async updateTransport(params: {
    transport: any;
    transportId: any;
  }): Promise<any> {
    const response = await API.updateTransport(
      params.transportId,
      params.transport
    );
    return response;
  }
}

export default getModule(TransportModule);
