import { postApi, getApi, deleteApi, patchApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import {
  userInfo,
  forgotPassword,
  resetPassword,
  activate,
  changePassword,
} from "@/store/model/jwt";
import { News, PaginatedNewsList } from "../model/news";

export default new (class NewsAPI {
  public async getPaginatedNewsList(query: any): Promise<any> {
    const url = `${Action.News}`;
    const response = await getApi(url, query);
    return response as PaginatedNewsList;
  }

  public async getNewsByID(id: any): Promise<any> {
    const url = `${Action.News}/${id}`;
    const response = await getApi(url);
    return response as News;
  }

  // public async deleteTransportById(id: any): Promise<any> {
  //   const url = `${Action.Transport}`
  //   const response = await deleteApi(url, id)
  //   return response
  // }

  public async createNews(param: any): Promise<any> {
    const url = `${Action.News}`;
    const response = await postApi(url, param, {
      "Content-Type": "multipart/form-data",
    });
    return response as News;
  }

  public async deleteNewsByID(id: any): Promise<any> {
    const url = `${Action.News}`;
    const response = await deleteApi(url, id);
    return response;
  }

  public async deleteNewsImageByID(id: any, param: any): Promise<any> {
    const url = `${Action.News}/${id}`;
    const response = await patchApi(url, param);
    return response;
  }

  public async patchNewsImage(id: any, param: any): Promise<any> {
    const url = `${Action.News}/${id}`;
    const response = await patchApi(url, param, {
      "Content-Type": "multipart/form-data",
    });
    return response as News;
  }

  public async patchNews(id: any, param: any): Promise<any> {
    const url = `${Action.News}/${id}`;
    const response = await patchApi(url, param, {
      "Content-Type": "multipart/form-data",
    });
    return response as News;
  }
})();
