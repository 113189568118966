import store from "@/store";
import {
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import API from "@/store/api/news";
import { PaginatedTransportList } from "../model/transport";
import { PaginatedNewsList } from "../model/news";

@Module({ name: "NewsStore", dynamic: true, store })
class NewsModule extends VuexModule {
  public PaginatedNewsList: PaginatedNewsList = {} as PaginatedNewsList;
  // public Transport: Transport = {} as Transport

  @MutationAction
  async getPaginatedNewsList(
    params: {
      searchString?: any;
      filters?: any;
      page?: any;
      market_id?: any;
    } = { searchString: undefined, page: 1 }
  ): Promise<any> {
    let query = "";
    if (
      params.searchString ||
      params.page ||
      params.filters ||
      params.market_id
    ) {
      query += "?";
      if (params.searchString) {
        query += "&search=" + params.searchString;
      }
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
      if (params.page) {
        query += "&page=" + params.page;
      }
      console.log("Market ID : -", params.market_id);
      if (params.market_id) {
        query += "&market=" + params.market_id;
      }
    }
    const response = await API.getPaginatedNewsList(query);
    console.log("res->", response);
    return { PaginatedNewsList: response };
  }

  @Action
  async getNewsByID(params: { newsId: any }): Promise<any> {
    const response = await API.getNewsByID(params.newsId);
    return response;
  }

  @Action
  async patchNewsImage(params: { newsId: any; news: any }): Promise<any> {
    const response = await API.patchNewsImage(params.newsId, params.news);
    return response;
  }

  @Action
  async patchNews(params: { newsId: any; news: any }): Promise<any> {
    const response = await API.patchNews(params.newsId, params.news);
    return response;
  }

  // @Action
  // async deleteTransportById(params:{ transportId: any }): Promise<any> {
  //     const response = await API.deleteTransportById(params.transportId)
  //     return response
  // }

  @Action
  async createNews(params: { news: any }): Promise<any> {
    const response = await API.createNews(params.news);
    return response;
  }

  @Action
  async deleteNewsByID(newsId: any): Promise<any> {
    const response = await API.deleteNewsByID(newsId);
    return response;
  }

  @Action
  async removeNewsImageByID(params: { newsId: any; news: any }): Promise<any> {
    const response = await API.deleteNewsImageByID(params.newsId, params.news);
    return response;
  }
}

export default getModule(NewsModule);
