import store from "@/store";
import {
  Module,
  VuexModule,
  MutationAction,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import API from "@/store/api/mmc_members";
import {
  MMCMembers,
  PaginatedMMCMembers,
  FilterMMCMembers,
  MmcDesignation,
} from "../model/mmc_members";
import { QTablePagination } from "../model/page";

@Module({ name: "MMCMembersStore", dynamic: true, store })
class MMCMembersModule extends VuexModule {
  public MMCMembers: MMCMembers[] = [];
  public SingleMmcMember: MMCMembers = {} as MMCMembers;
  public PaginatedMMCMembers: PaginatedMMCMembers = {} as PaginatedMMCMembers;
  public MmcDesignationList: MmcDesignation[] = [];
  public QTablePaginationData: QTablePagination = {
    sortBy: "id",
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1,
  };

  @Mutation
  setMmcMember(data: MMCMembers) {
    this.SingleMmcMember = data;
  }

  @Mutation
  setMmcPagination(data: QTablePagination) {
    this.QTablePaginationData = data;
  }

  @MutationAction
  async getMMCMembers(
    params: {
      searchString?: any;
      filters?: any;
      page?: any;
      market_id?: any;
    } = { searchString: undefined, page: 1 }
  ): Promise<any> {
    let query = "";
    if (
      params.searchString ||
      params.page ||
      params.filters ||
      params.market_id
    ) {
      query += "?";
      if (params.searchString) {
        query += "&search=" + params.searchString;
      }
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
      if (params.page) {
        query += "&page=" + params.page;
      }
      //   console.log("ii", params.market_id);
      if (params.market_id) {
        query += "&market=" + params.market_id;
      }
    }
    const response = await API.GetMMCMembers(query);
    console.log(response);
    return { MMCMembers: response };
  }

  @MutationAction
  async getPaginatedMMCMembers(
    params: FilterMMCMembers = { search: "", page: 1 }
  ): Promise<any> {
    let query = "";
    if (params.search || params.page || params.market || params.filters) {
      query += "?";
      if (params.search) {
        query += "&search=" + params.search;
      }
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
      if (params.page) {
        query += "&page=" + params.page;
      }
      //   console.log("ii", params.market_id);
      if (params.market) {
        query += "&market=" + params.market;
      }
    }
    const response = await API.getPaginatedMMCMembers(query);
    console.log(response);
    return { PaginatedMMCMembers: response };
  }

  @Action
  async CreateMMCMember(params: { data: FormData }): Promise<any> {
    const response = await API.CreateMMCMember(params.data);
    return response;
  }

  @Action
  async editMMCMember(params: { data: FormData; id: number }): Promise<any> {
    const response = await API.editMMCMember(params.data, params.id);
    return response;
  }

  @MutationAction
  async getMMCMemberById(mcc_id: any): Promise<any> {
    const response = await API.getMMCMemberById(mcc_id);
    return { SingleMmcMember: response };
  }
  @Action
  async deleteMmcById(params: { id: number }): Promise<any> {
    const response = await API.deleteMmcMember(params.id);
    return response;
  }
  @MutationAction
  async getMmcDesignation(): Promise<any> {
    const response = await API.getMmcDesignation();
    return { MmcDesignationList: response };
  }
}

export default getModule(MMCMembersModule);
