import store from "@/store";
import {
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import API from "@/store/api/trader";
import { PaginatedProductList } from "../model/market";
import { QTablePagination } from "../model/page";
import { FilterTraders, PaginatedTraderList, Trader } from "../model/trader";

@Module({ name: "TraderStore", dynamic: true, store })
class TraderModule extends VuexModule {
  public SingleTrader: Trader = {} as Trader;
  public PaginatedTraderList: PaginatedTraderList = {} as PaginatedTraderList;
  public QTablePaginationData: QTablePagination = {
    sortBy: "id",
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1,
  };

  @Mutation
  setTrader(data: Trader) {
    this.SingleTrader = data;
  }

  @Mutation
  setTradersPagination(data: QTablePagination) {
    this.QTablePaginationData = data;
  }

  @MutationAction
  async getPaginatedTraderList(
    params: FilterTraders = { search: "", page: 1 }
  ): Promise<any> {
    let query = "";
    if (params.search || params.page || params.filters || params.market) {
      query += "?";
      if (params.search) {
        query += "&search=" + params.search;
      }
      if (params.filters) {
        Object.keys(params.filters).forEach((key) => {
          query += `&${key}=${params.filters[key]}`;
        });
      }
      if (params.page) {
        query += "&page=" + params.page;
      }
      //   console.log("ii", params.market_id);
      if (params.market) {
        query += "&market=" + params.market;
      }
    }
    const response = await API.getPaginatedTraderList(query);
    return { PaginatedTraderList: response };
  }

  @Action
  async createTrader(params: { trader: any }): Promise<any> {
    const response = await API.createTrader(params.trader);
    return response;
  }

  @Action
  async editTrader(params: { data: FormData; id: number }): Promise<any> {
    const response = await API.editTrder(params.data, params.id);
    return response;
  }

  @Action
  async deleteTradersById(params: { id: number }): Promise<any> {
    const response = await API.deleteTrader(params.id);
    return response;
  }

  @MutationAction
  async getTraderByID(traderId: any): Promise<any> {
    const response = await API.getTraderByID(traderId);
    return { SingleTrader: response };
  }
}

export default getModule(TraderModule);
