import { postApi, getApi, deleteApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import {
  userInfo,
  forgotPassword,
  resetPassword,
  activate,
  changePassword,
} from "@/store/model/jwt";
// import { PaginatedTransportList, Transport } from "../model/transport";

export default new (class AdministrativeAreaAPI {
  public async getProvinceList(): Promise<any> {
    const url = `${Action.Province}`;
    const response = await getApi(url);
    return response;
  }

  public async getDisrictList(): Promise<any> {
    const url = `${Action.District}`;
    const response = await getApi(url);
    return response;
  }

  public async getPalikaList(): Promise<any> {
    const url = `${Action.Palika}`;
    const response = await getApi(url);
    return response;
  }

  public async getDistrictByProvinceId(query: any): Promise<any> {
    const url = `${Action.District}`;
    const response = await getApi(url, query);
    return response;
  }

  public async getPalikaByDistrictId(query: any): Promise<any> {
    const url = `${Action.Palika}`;
    const response = await getApi(url, query);
    return response;
  }
})();
