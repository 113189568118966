import store from "@/store";
import {
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import API from "@/store/api/news";
import { PaginatedTransportList } from "../model/transport";
import { PaginatedNewsList } from "../model/news";

@Module({ name: "UiStore", dynamic: true, store })
class UiModule extends VuexModule {
  public isDrawerVisible = false;

  @Mutation
  async toggleDrawerVisibility() {
    const bool = this.isDrawerVisible;
    this.isDrawerVisible = !bool;
    console.log(this.isDrawerVisible);
  }
}

export default getModule(UiModule);
