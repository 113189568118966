
import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    title: String,
  },
})
export default class ContentHeader extends Vue {
  backgroundImage = "";
}
