import { postApi, getApi, patchApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import { PaginatedProductList, Product } from "../model/market";
import { MarketStore } from "../modules";

export default new (class MarketAPI {
  public async getPaginatedProductList(query: any): Promise<any> {
    const url = `${Action.Product}`;
    const response = await getApi(url, query);
    return response as PaginatedProductList;
  }
  public async getMarketPriceDates(): Promise<any> {
    const url = `${Action.ProductPriceDate.replace(
      "<marketId>",
      MarketStore.MARKETPLACE_ID
    )}`;
    const response = await getApi(url);
    return response;
  }

  public async getProductCategoryList(): Promise<any> {
    const url = `${Action.Category}`;
    const response = await getApi(url);
    return response;
  }

  public async getMarketsList(): Promise<any> {
    const url = `${Action.Markets}`;
    const response = await getApi(url);
    return response;
  }

  public async getPaginatedProductPriceList(query: any): Promise<any> {
    const url = `${Action.ProductPrice}`;
    const response = await getApi(url, query);
    return response;
  }

  public async getPaginatedProductPriceRangeList(
    startDate: string,
    endDate: string,
    query: any
  ): Promise<any> {
    console.log("Dates", startDate, endDate);
    const url = `${Action.ProductPriceDateRangeList.replace(
      "<marketId>",
      MarketStore.MARKETPLACE_ID
    )
      .replace("<nepStartDate>", startDate)
      .replace("<nepEndDate>", endDate)}`;
    const response = await getApi(url, query);
    return response;
  }

  public async getProductList(query: any): Promise<any> {
    const url = `${Action.Product}`;
    const response = await getApi(url, query);
    return response;
  }

  public async getProductCategoryByID(id: any): Promise<any> {
    const url = `${Action.Category}/${id}`;
    const response = await getApi(url);
    return response;
  }

  public async getProductPriceListID(id: any, query: any): Promise<any> {
    const url = `${Action.ProductPriceDetail.replace("<id>", id)}`;
    const response = await getApi(url, query);
    return response;
  }

  public async getProductByID(id: any): Promise<any> {
    const url = `${Action.Product}/${id}`;
    const response = await getApi(url);
    return response;
  }

  public async getMarketSummary(query: any): Promise<any> {
    const url = `${Action.Summary}`;
    const response = await getApi(url, query);
    return response;
  }
  public async getProductPriceList(query: any): Promise<any> {
    const url = `${Action.ProductPriceList}`;
    const response = await getApi(url, query);
    return response;
  }

  public async getMarketLineChart(query: any): Promise<any> {
    const url = `${Action.LineChart}`;
    const response = await getApi(url, query);
    return response;
  }
  public async getMarketTimeSeriesLineHighChart(query: any): Promise<any> {
    const url = `${Action.TimeSeriesLineChart}`;
    const response = await getApi(url, query);
    return response;
  }
  public async getTransportChartData(query: any): Promise<any> {
    const url = `${Action.TransportChart}`;
    const response = await getApi(url, query);
    return response;
  }
  public async editProductPrice(
    data: any,
    id: number,
    market_id: number
  ): Promise<any> {
    const url = `${Action.ProductPriceDetail.replace(
      "<id>",
      String(id)
    )}/?&market=${market_id}`;
    console.log(data);
    await patchApi(url, data);
  }
})();
